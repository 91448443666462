import { formatDistanceToNow } from "date-fns";
import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import AsyncTable from "./AsyncTable.js";

function addIndices(data) {
  for (let i = 0; i < data.length; i++) {
    data[i].ix = i;
  }
  return data;
}

class Metenoxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHidden: false,
    };
  }
  handleInputChange(evt) {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  render() {
    const spacerStyle = {
      marginTop: "10px",
      marginBottom: "10px",
      marginRight: "20px",
    };

    const columns = [
      {
        text: "Name",
        dataField: "structure_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Region",
        dataField: "solar_system.region.region_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "120px",
        },
      },
      {
        text: "Const",
        dataField: "solar_system.constellation.constellation_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "90px",
        },
      },
      {
        text: "Gas Expires",
        dataField: "gas_expires",
        sort: true,
        formatter: (x) =>
          formatDistanceToNow(new Date(x), {
            addSuffix: true,
          }),
        headerStyle: {
          width: "130px",
        },
      },
      {
        text: "Fuel Expires",
        dataField: "fuel_expires",
        sort: true,
        formatter: (x) =>
          formatDistanceToNow(new Date(x), {
            addSuffix: true,
          }),
        headerStyle: {
          width: "130px",
        },
      },
      {
        text: "Moon Mats Value",
        dataField: "total_value",
        sort: true,
        formatter: (x) => x.toFixed(0).toLocaleString() + " M",
        headerStyle: {
          width: "130px",
        },
      },
    ];
    let spinner = "";
    if (this.state.hasInflight) {
      spinner = <Spinner color="primary" className="float-right" />;
    }
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>Metenoxes</h1>
            </Col>
            <Col>
              <Form className="float-right">
                <FormGroup check style={spacerStyle}>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="showHidden"
                      id="inputShowHiddenCits"
                      checked={this.state.showHidden}
                      onChange={(e) => this.handleInputChange(e)}
                    />{" "}
                    Show Hidden Metenoxes
                  </Label>
                </FormGroup>
              </Form>
              {spinner}
            </Col>
          </Row>
          <Row>
            <Col className="no-padding">
              <AsyncTable
                requestUrl={"metenoxes"}
                rowFilter={addIndices}
                columnDefinition={columns}
                keyField={"item_id"}
                sortKey={"total_value"}
                fluid
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  getDS() {
    if (this.state.dataOverride) {
      return this.state.dataOverride;
    } else {
      return this.dataStash;
    }
  }
}

export default Metenoxes;
