const serviceDef = {
  "Automatic Moon Drilling": {
    consumption: 5,
    kind: "other",
  },
  "Moon Drilling": {
    consumption: 5,
    kind: "other",
  },
  "Cynosural Jammer": {
    consumption: 0,
    kind: "other",
  },
  Reprocessing: {
    consumption: 10,
    kind: "repro",
  },
  "Composite Reactions": {
    consumption: 15,
    kind: "repro",
  },
  "Biochemical Reactions": {
    consumption: 15,
    kind: "repro",
  },
  "Hybrid Reactions": {
    consumption: 15,
    kind: "repro",
  },
  "Jump Access": {
    consumption: 15,
    kind: "other",
  },
  "Jump Bridge Access": {
    consumption: 30,
    kind: "other",
  },
  "Clone Bay": {
    consumption: 10,
    kind: "citadel",
  },
  Market: {
    consumption: 40,
    kind: "citadel",
  },
  Research: {
    consumption: 12,
    kind: "manufacture",
  },
  Invention: {
    consumption: 12,
    kind: "manufacture",
  },
  "Manufacturing (Standard)": {
    consumption: 12,
    kind: "manufacture",
  },
  "Manufacturing (Capitals)": {
    consumption: 24,
    kind: "manufacture",
  },
  "Manufacturing (Super Capitals)": {
    consumption: 36,
    kind: "manufacture",
  },
};
const citadelDef = {
  35825: {
    name: " (EC)",
    citadel: 1,
    manufacture: 0.75,
    other: 1,
    repro: 1,
  },
  35826: {
    name: " (EC)",
    citadel: 1,
    manufacture: 0.75,
    other: 1,
    repro: 1,
  },
  35827: {
    name: " (EC)",
    citadel: 1,
    manufacture: 0.75,
    other: 1,
    repro: 1,
  },
  35832: {
    name: " (Citadel)",
    citadel: 0.75,
    manufacture: 1,
    other: 1,
    repro: 1,
  },
  35833: {
    name: " (Citadel)",
    citadel: 0.75,
    manufacture: 1,
    other: 1,
    repro: 1,
  },
  35834: {
    name: " (Citadel)",
    citadel: 0.75,
    manufacture: 1,
    other: 1,
    repro: 1,
  },
  35840: {
    name: "",
    citadel: 1,
    manufacture: 1,
    other: 1,
    repro: 1,
  },
  35841: {
    name: "",
    citadel: 1,
    manufacture: 1,
    other: 1,
    repro: 1,
  },
  81826: {
    name: "",
    citadel: 1,
    manufacture: 1,
    other: 1,
    repro: 1,
  },
  37534: {
    name: "",
    citadel: 1,
    manufacture: 1,
    other: 1,
    repro: 1,
  },
  35835: {
    name: " (Athanor)",
    citadel: 1,
    manufacture: 1,
    other: 1,
    repro: 0.8,
  },
  35836: {
    name: " (Tatara)",
    citadel: 1,
    manufacture: 1,
    other: 1,
    repro: 0.75,
  },
  47513: {
    name: " (Amarr/Caldari fort)",
    citadel: 0.75,
    manufacture: 0.75,
    other: 1,
    repro: 1,
  },
  47514: {
    name: " (Amarr/Caldari fort)",
    citadel: 0.75,
    manufacture: 0.75,
    other: 1,
    repro: 1,
  },
  47516: {
    name: " (Minmatar fort)",
    citadel: 0.75,
    manufacture: 1,
    other: 1,
    repro: 0.75,
  },
  47512: {
    name: " (Conq fort)",
    citadel: 0.65,
    manufacture: 0.65,
    other: 1,
    repro: 0.65,
  },
  47515: {
    name: " (Gallente fort)",
    citadel: 0.5,
    manufacture: 0.5,
    other: 1,
    repro: 0.5,
  },
};

export { citadelDef, serviceDef };
