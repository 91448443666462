import { hasSearchPermissions, Permissions } from "../Access";

const ROUTES = {
  TIMERS: "/timers",
  ARCHIVED_TIMERS: "/timers/archived",
  FOZZIESOV_TIMERS: "/timers/fozziesov",
  ADD_TIMER: "/timers/new",
  VIEW_TIMER: "/timers/:timerId",
  EDIT_TIMER: "/timers/:timerId/edit",
  TASKS_ACTIVE: "/tasks/active",
  TASKS_ARCHIVED: "/tasks/archived",
  TASKS_LEADERBOARD: "/tasks/leaderboard",
  GSOL_CITADELS: "/gsol/citadels",
  GSOL_MOON_RENTALS: "/gsol/moon-rentals",
  GSOL_MOON_FRACKS: "/gsol/moon-fracks",
  GSOL_STARGATES: "/gsol/stargates",
  GSOL_METENOXES: "/gsol/metenoxes",
  GSOL_IHUBS: "/gsol/ihubs",
  GSOL_UNANCHORING: "/gsol/unanchoring",
  GSOL_POS: "/gsol/pos",
  REPORTS_FUEL: "/reports/fuel",
  REPORTS_INACTIVE_MINERS: "/reports/inactive-miners",
  REPORTS_SEARCH_ASSETS: "/reports/search-assets",
  REPORTS_NOTIFICATIONS: "/reports/notifications",
  REPORTS_QUARTERMASTER: "/reports/quartermaster",
  REPORTS_BLUEPRINTS_STRUCTURE: "/reports/blueprints/structurecomps",
  REPORTS_BLUEPRINTS_SOVINFRA: "/reports/blueprints/sovinfra",
  REPORTS_CORELESS: "/reports/coreless",
  ADMIN_USERS: "/admin/user",
  ADMIN_ROLES: "/admin/roles",
  ADMIN_TASKS: "/admin/tasks",
  ADMIN_TOOLS: "/admin/tools",
  ADMIN_TOKENS: "/admin/tokens",
  LOGIN: "/login",
  TOKEN_SUBMIT_SUCCESS: "/tokens/submit_success",
  API_ADD_TOKEN: "/api/tokens/redirect_to_ccp",
  API_LOGOUT: "/api/logout",
  API_LOGIN: "/api/login",
  USER_CHARACTERS: "/user/characters",
};

export const createViewTimerUrl = (timerId) =>
  ROUTES.VIEW_TIMER.replace(":timerId", timerId);

export const createEditTimerUrl = (timerId) =>
  ROUTES.EDIT_TIMER.replace(":timerId", timerId);

export default ROUTES;

export const SITEMAP = [
  {
    name: "Timerboard",
    links: [
      {
        name: "Active Timers",
        link: ROUTES.TIMERS,
        role: Permissions.SEE_TIMERS,
      },
      {
        name: "Archived Timers",
        link: ROUTES.ARCHIVED_TIMERS,
        role: Permissions.SEE_TIMERS,
      },
      {
        name: "FozzieSov Timers",
        link: ROUTES.FOZZIESOV_TIMERS,
      },
      {
        name: "Create Timer",
        link: ROUTES.ADD_TIMER,
        role: Permissions.ADD_TIMER,
      },
    ],
  },
  {
    name: "Tasks",
    links: [
      {
        name: "Active",
        link: ROUTES.TASKS_ACTIVE,
        role: Permissions.SEE_TASKS,
      },
      {
        name: "Archived",
        link: ROUTES.TASKS_ARCHIVED,
        role: Permissions.SEE_TASKS,
      },
      {
        name: "Leaderboard",
        link: ROUTES.TASKS_LEADERBOARD,
        role: Permissions.SEE_TASKS,
      },
    ],
  },
  {
    name: "GSOL",
    links: [
      {
        name: "Citadels",
        link: ROUTES.GSOL_CITADELS,
        role: Permissions.SEE_CITADELS,
      },
      {
        name: "Moon Rentals",
        link: ROUTES.GSOL_MOON_RENTALS,
        role: Permissions.SEE_CITADELS,
      },
      {
        name: "Fracks",
        link: ROUTES.GSOL_MOON_FRACKS,
        role: Permissions.SEE_MOON_FRACKS,
      },
      {
        name: "StarGates",
        link: ROUTES.GSOL_STARGATES,
        role: Permissions.SEE_STARGATES,
      },
      {
        name: "Metenoxes",
        link: ROUTES.GSOL_METENOXES,
        role: Permissions.SEE_STARGATES,
      },
      {
        name: "I-Hub Upgrades",
        link: ROUTES.GSOL_IHUBS,
        role: Permissions.SEE_IHUBS,
      },
      {
        name: "Unanchoring citadels",
        link: ROUTES.GSOL_UNANCHORING,
        role: Permissions.SEE_UNANCHORING,
      },
      {
        name: "POS",
        link: ROUTES.GSOL_POS,
        role: Permissions.SEE_CITADELS,
      },
    ],
  },
  {
    name: "Reports",
    links: [
      {
        name: "Fuel Budget Report",
        link: ROUTES.REPORTS_FUEL,
        role: Permissions.SEE_CITADELS,
      },
      {
        name: "Inactive Miners",
        link: ROUTES.REPORTS_INACTIVE_MINERS,
        role: Permissions.SEE_INACTIVE_FRACK_REPORT,
      },
      {
        name: "Asset Search",
        link: ROUTES.REPORTS_SEARCH_ASSETS,
        role: hasSearchPermissions,
      },
      {
        name: "Notifications",
        link: ROUTES.REPORTS_NOTIFICATIONS,
        role: Permissions.SEE_NOTIFICATIONS,
      },
      {
        name: "Quartermaster Report",
        link: ROUTES.REPORTS_QUARTERMASTER,
        role: Permissions.SEE_QUARTERMASTER_REPORT,
      },
      {
        name: "Structure Component BPCs",
        link: ROUTES.REPORTS_BLUEPRINTS_STRUCTURE,
        role: Permissions.SEE_QUARTERMASTER_REPORT,
      },
      {
        name: "Sov & Infra BPCs",
        link: ROUTES.REPORTS_BLUEPRINTS_SOVINFRA,
        role: Permissions.SEE_QUARTERMASTER_REPORT,
      },
      {
        name: "Coreless",
        link: ROUTES.REPORTS_CORELESS,
        role: Permissions.SEE_CITADELS,
      },
    ],
  },
  {
    name: "Admin",
    links: [
      {
        name: "User Management",
        link: ROUTES.ADMIN_USERS,
        role: Permissions.EDIT_ROLES,
      },
      {
        name: "Role Management",
        link: ROUTES.ADMIN_ROLES,
        role: Permissions.EDIT_ROLES,
      },
      {
        name: "Task Type Management",
        link: ROUTES.ADMIN_TASKS,
        role: Permissions.EDIT_TASK_TYPES,
      },
      {
        name: "Tools",
        link: ROUTES.ADMIN_TOOLS,
        role: [Permissions.EDIT_CITADEL_META, Permissions.SET_DESIRED_FUEL],
      },
      {
        name: "Esi Tokens",
        link: ROUTES.ADMIN_TOKENS,
        role: Permissions.MANAGE_TOKENS,
      },
    ],
  },
  {
    name: "Add Token",
    hrefLink: ROUTES.API_ADD_TOKEN,
    role: Permissions.ADD_TOKENS,
  },
  {
    name: "User Settings",
    hrefLink: ROUTES.USER_CHARACTERS,
    role: Permissions.SEE_TASKS,
  },
  {
    name: "Logout",
    hrefLink: ROUTES.API_LOGOUT,
  },
];
